.App {
  text-align: center;
  display: flex;
  height: 100vh;
}
.Header
{
  display: inline-flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  background-image: linear-gradient(to bottom, #4e4ea5, #3d3d88, #2d2c6c, #1d1d52, #0e0e39);
  color: whitesmoke;
  letter-spacing: -1px;
  overflow-y: hidden;
}
.Heading
{
  padding: 0% 15%;
}
.Header .List
{
  margin-top: 8%;
}
.Header .List div
{
  list-style: none;
  font-size: 20px;
  letter-spacing: -0.5px;
  padding: 3% 25% 3% 25%;
}
.DisplayPic
{
  padding-top: 15%;
}
.DisplayPic img
{
  width: 25%;
  border: 5px solid rgb(45, 48, 66);
  border-radius: 60px;
}
.SocialMedia
{
  display: inline-flex; 
  flex-direction: row; 
  font-size: 25px;
  margin: 60% 0% 5% 22%;
  text-decoration: none;
}
.SocialMedia div
{
  margin: 0% 0% 0% 4%;
}
.HomeContent
{
  display: inline-flex;
  flex-direction: row;
  width: 75%;
  height: auto;
  color: whitesmoke;
  background-image: url('/public/Images/MyImage2.jpg');
  background-size: 75% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top right;
}
.HomeContent h1
{
  text-align: left;
  margin: 25% 0% 0% 60%;
  font-size: 38px;
}
.AboutContent
{
  display: inline-flex;
  flex-direction: column;
  width: 75%;
  height: 100vh;
  overflow-x: scroll;
}
.AboutContent div h1
{
  padding: 2% 0% 2% 0%;
  text-decoration-line: underline;
}
.AboutContent2
{
  text-align: left;
  display: inline-flex;
  flex-direction: row;
}
.AboutContent2 div
{
  display: inline-flex;
  flex-direction: row;
}
.AboutContent2 .AboutMe
{
  width: 20%;
  height: 55%;
  justify-content: center;
  text-align: center;
  margin: 5% 5% 5% 5%;
  font-size: 14px;
  border: none;
  background-color: rgb(78, 78, 165);
  color: whitesmoke;
}
.AboutContent2 div img
{
  width: 35%;
  padding: 10% 5% 5% 5%;
}
.AboutContent2 .AboutButton
{
  display: inline-flex;
  flex-direction: row;
  height: 15%;
  text-align: center;
}
.AboutContent2 .AboutRight
{
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.AboutContent2 .AboutParagraph
{
  flex-direction: column;
  padding: 3% 0% 0% 5%;
}
.ResumeContent
{
  display: inline-flex;
  flex-direction: column;
  width: 75%;
  height: 100vh;
  overflow-y: scroll;
}
.ResumeContent div h1
{
  padding: 2% 0% 0% 5%;
  text-decoration-line: underline;
}
.GalleryContent
{
  display: inline-flex;
  flex-direction: column;
  width: 75%;
  height: 100vh;
  overflow-y: scroll;
}
.GalleryContent div h1
{
  padding: 3% 0% 3% 0%;
  text-decoration-line: underline;
}
.Gallery1
{
  display: flex;
  justify-content: center;
}
.Graduation
{
  display: inline-flex;
  margin: 0% 3% 2% 3%; 
  width: 300px ; 
  height: 300px ;
  border: 2px solid rgb(78, 78, 165);
  justify-content: center;
  background-image: url('/public/Images/MyImage4.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: whitesmoke;
  font-size: 22px;
  font-weight: bold;
}
.Graduation:hover
{
  background-size: 125% 125%;
  background-position: center;
  background-color: rgb(78, 78, 165);
  opacity: 0.7;
  transition: all 1s ease-in;
  cursor: pointer;
}
.MainGallery .GalleryMe
{
  display: flex;
  flex-wrap: wrap;
}
.GalleryMe
{
  display: inline-flex;
  justify-content: center;
  margin: 5% 0% 5% 5%;
  width: 90%;
}
.Gallery2
{
  width: calc(100%/3);
  height: 80%;
}
.Gallery2 img
{
  width: 100%;
  height: 100%;
  vertical-align: middle;
  transition: all 1s ease-in;
  padding: 2% 2% 0% 2%;
}
.Gallery2 img:hover
{
  transform: scale(1.1);
}
.Travel1 
{
  display: inline-flex;
  margin: 0% 3% 2% 3%; 
  width: 300px ; 
  height: 300px ;
  border: 2px solid rgb(78, 78, 165);
  justify-content: center;
  background-image: url('/public/Images/MyImage3.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: whitesmoke;
  font-size: 22px;
  font-weight: bold;
}
.Travel1:hover
{
  background-size: 125% 125%;
  background-position: center;
  background-color: rgb(78, 78, 165);
  opacity: 0.7;
  transition: all 1s ease-in;
  cursor: pointer;
}
.Travel2
{
  display: inline-flex;
  margin: 0% 3% 2% 3%; 
  width: 300px ; 
  height: 300px ;
  border: 2px solid rgb(78, 78, 165);
  justify-content: center;
  background-image: url('/public/Images/MyImage5.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: whitesmoke;
  font-size: 22px;
  font-weight: bold;
}
.Travel2:hover
{
  background-size: 125% 125%;
  background-position: top center;
  background-color: rgb(78, 78, 165);
  opacity: 0.7;
  transition: all 1s ease-in;
  cursor: pointer;
}
.ContactContent
{
  display: inline-flex;
  flex-direction: column;
  width: 75%;
  height: 100vh;
  overflow-y: scroll;
}
.ContactContent div h1
{
  padding: 2% 0% 2% 0%;
  text-decoration-line: underline;
}
.ContactLink1
{
  width: 70%;
  height: 80%;
  background-image: linear-gradient(to right, #4e4ea5, #403f90, #32307b, #242267, #151554);
  justify-content: center;
  margin: 1% 16% 1% 16%;
  border-radius: 10px;
}
.ContactLink2
{
  width: 70%;
  height: 80%;
  background-image: linear-gradient(to left, #4e4ea5, #403f90, #32307b, #242267, #151554);
  justify-content: center;
  margin: 1% 16% 1% 16%;
  border-radius: 10px;
}


@media only screen and (max-width: 500px) {
  .App {
    text-align: center;
    display: flex;
    height: 100vh;
  }
  .Header
  {
    display: inline-flex;
    flex-direction: column;
    width: 45%;
    height: 100vh;
    background-image: linear-gradient(to bottom, #4e4ea5, #3d3d88, #2d2c6c, #1d1d52, #0e0e39);
    color: whitesmoke;
    letter-spacing: -1px;
    overflow-y: hidden;
  }
  .Heading
  {
    padding: 0% 15%;
    font-size: 14px;
  }
  .Header .List
  {
    margin-top: 55%;
  }
  .Header .List div
  {
    list-style: none;
    font-size: 14px;
    padding: 8% 25% 8% 25%;
  }
  .DisplayPic
  {
    padding-top: 35%;
    padding-bottom: 5%;
  }
  .DisplayPic img
  {
    width: 45%;
    border: 5px solid rgb(45, 48, 66);
    border-radius: 60px;
  }
  .SocialMedia
  {
    display: inline-flex; 
    flex-direction: row; 
    font-size: 16px;
    text-align: center;
    text-decoration: none;
  }
  .SocialMedia div
  {
    margin: 90% 2% 0% 2%;
  }
  .HomeContent
  {
    display: inline-flex;
    flex-direction: row;
    width: 55%;
    height: 100vh;
    color: whitesmoke;
    background-image: url('/public/Images/MyImage3.jpg');
    background-size: 76% 100vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: top right;
    overflow-y: scroll;
  }
  .HomeContent h1
  {
    margin: 250% 0% 0% 20%;
    font-size: 18px;
  }
  .AboutContent
  {
    display: inline-flex;
    flex-direction: column;
    width: 55%;
    height: 100vh;
    overflow-x: scroll;
  }
  .AboutContent div h1
  {
    padding: 2% 0% 2% 0%;
    font-size: 20px;
    text-decoration-line: underline;
  }
  .AboutContent2
  {
    text-align: center;
    display: inline-flex;
    flex-direction: column;
  }
  .AboutContent2 div
  {
    display: inline-flex;
    flex-direction: column;
  }
  .AboutContent2 .AboutMe
  {
    width: 40%;
    height: 12vh;
    justify-content: center;
    text-align: center;
    margin: 4% 35% 4% 35%;
    font-size: 10px;
    border: none;
    background-color: rgb(78, 78, 165);
    color: whitesmoke;
  }
  .AboutContent2 div img
  {
    width: 72%;
    padding: 15% 15% 15% 15%;
  }
  .AboutContent2 .AboutButton
  {
    display: inline-flex;
    flex-direction: column;
    height: 25%;
    text-align: center;
  }
  .AboutContent2 .AboutRight
  {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }
  .AboutContent2 .AboutParagraph
  {
    flex-direction: column;
    padding: 3% 0% 0% 5%;
  }
  .AboutContent2 .AboutParagraph p
  {
    font-size: 14px;
  }
  .ResumeContent
  {
    display: inline-flex;
    flex-direction: column;
    width: 55%;
    height: 100vh;
    overflow-y: scroll;
  }
  .ResumeContent div h1
  {
    padding: 2% 0% 0% 5%;
    font-size: 20px;
    text-decoration-line: underline;
  }

  .GalleryContent
  {
    display: inline-flex;
    flex-direction: column;
    width: 55%;
    height: 100vh;
    overflow-y: scroll;
  }
  .GalleryContent div h1
  {
    padding: 3% 0% 3% 0%;
    font-size: 20px;
    text-decoration-line: underline;
  }
  .Gallery1
  {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .Graduation
  {
    width: 200px;
    height: 200px;
    justify-content: center;
    background-image: url('/public/Images/MyImage4.jpg');
    background-size: 100% 100%;
    background-position: top left;
    background-repeat: no-repeat;
    color: whitesmoke;
    font-size: 22px;
    font-weight: bold;
  }
  .Graduation:hover
  {
    background-size: 125% 125%;
    background-position: center;
    background-color: rgb(78, 78, 165);
    opacity: 0.7;
    transition: all 1s ease-in;
    cursor: pointer;
  }
  .MainGallery .GalleryMe
  {
    display: flex;
    flex-wrap: wrap;
  }
  .GalleryMe
  {
    display: inline-flex;
    justify-content: center;
    margin: 5% 0% 5% 5%;
    width: 90%;
  }
  .Gallery2
  {
    width: calc(100%/2);
    height: 100%;
  }
  .Gallery2 img
  {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    transition: all 1s ease-in;
    padding: 2% 2% 0% 2%;
  }
  .Gallery2 img:hover
  {
    transform: scale(1.1);
  }
  .Travel1 
  {
    width: 200px;
    height: 200px;
    justify-content: center;
    background-image: url('/public/Images/MyImage3.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: whitesmoke;
    font-size: 22px;
    font-weight: bold;
  }
  .Travel1:hover
  {
    background-size: 125% 125%;
    background-position: center;
    background-color: rgb(78, 78, 165);
    opacity: 0.7;
    transition: all 1s ease-in;
    cursor: pointer;
  }
  .Travel2
  {
    width: 200px;
    height: 200px;
    justify-content: center;
    background-image: url('/public/Images/MyImage5.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: whitesmoke;
    font-size: 22px;
    font-weight: bold;
  }
  .Travel2:hover
  {
    background-size: 125% 125%;
    background-position: top center;
    background-color: rgb(78, 78, 165);
    opacity: 0.7;
    transition: all 1s ease-in;
    cursor: pointer;
  }
  .ContactContent
  {
    display: inline-flex;
    flex-direction: column;
    width: 55%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .ContactContent div h1
  {
    padding: 3% 0% 3% 0%;
    font-size: 20px;
    text-decoration-line: underline;
  }
  .ContactLink1
  {
    width: 100%;
    height: 80%;
    background-image: linear-gradient(to right, #4e4ea5, #403f90, #32307b, #242267, #151554);
    justify-content: center;
    margin: 1% 16% 1% 16%;
    border-radius: 10px;
  }
  .ContactLink1 a h2
  {
    width: 100%;
    font-size: 8px;
  }
  .ContactLink2
  {
    width: 100%;
    height: 80%;
    background-image: linear-gradient(to left, #4e4ea5, #403f90, #32307b, #242267, #151554);
    justify-content: center;
    margin: 1% 16% 1% 16%;
    border-radius: 10px;
  }
  .ContactLink2 a h2
  {
    width: 100%;
    font-size: 7px;
  }
}